import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type DateRangeDialogComponent } from './date-range-dialog/date-range-dialog.component';

export interface DateRangeDialogOptions {
  title: string;
  startDate: Date;
  endDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class DateRangeDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(
    opts: DateRangeDialogOptions,
    restoreFocus = true
  ): Promise<MatDialogRef<DateRangeDialogComponent, { start: Date; end: Date }>> {
    const dateRangeDialogComponent = await import('./date-range-dialog/date-range-dialog.component').then(
      (m) => m.DateRangeDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(dateRangeDialogComponent, {
      panelClass: ['date-range-dialog', 'basic-dialog'],
      data: opts,
      restoreFocus,
      maxWidth: undefined
    });
  }
}
