import { ScrollDispatcher, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  output,
  signal
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { SortDirection } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DateRangeDialogService } from '@ih/date-range-dialog';
import {
  InputDialogComponent,
  InputDialogService,
  OptionPickerDialogComponent,
  OptionPickerDialogService
} from '@ih/dialogs';
import { FilterChipType } from '@ih/enums';
import { ListFilterItem, ListFilterItemValue } from '@ih/interfaces';
import { isObject } from '@ih/utilities';
import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  startWith,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';

const MAX_FILTERS = 4;

export interface Sort {
  field: string;
  direction: SortDirection;
  type: FilterChipType;
  sortSvgIcon?: string;
}

export interface SortField {
  title: string;
  value: string;
  type: FilterChipType;
  defaultSortDirection?: SortDirection;
}

export interface GroupByField {
  title: string;
  value: string;
}

export interface DateRange {
  start: Date;
  end: Date;
}

@Component({
  standalone: true,
  selector: 'ih-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ScrollingModule,
    InputDialogComponent,
    MatSelectModule,
    OptionPickerDialogComponent
  ],
  providers: [
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: MAT_CHIPS_DEFAULT_OPTIONS }
  ]
})
export class FilterChipsComponent implements OnInit, OnDestroy {
  @ViewChild('filterInput') filterInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto') auto!: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;

  private inputDialog = inject(InputDialogService);
  private optionsDialog = inject(OptionPickerDialogService);
  private dateRangeDialog = inject(DateRangeDialogService);
  private cd = inject(ChangeDetectorRef);
  public scroll = inject(ScrollDispatcher);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  filterChanged = output<ListFilterItem<unknown>[]>();
  sortChanged = output<Sort>();
  groupByChanged = output<GroupByField>();

  private types$ = new BehaviorSubject<ListFilterItem<unknown>[]>([]);
  @Input()
  get types(): ListFilterItem<unknown>[] {
    return this.types$.value;
  }

  set types(value: ListFilterItem<unknown>[]) {
    this.types$.next(value);
  }

  sortFields = signal<SortField[]>([]);
  groupByFields = signal<GroupByField[]>([]);

  inputFocused = false;

  selectedFilters$ = new BehaviorSubject<ListFilterItem<unknown>[]>([]);

  selectedSort$ = new BehaviorSubject<Sort | null>(null);
  selectedGroupBy$ = new BehaviorSubject<GroupByField | null>(null);

  filterQuery = new FormControl<string | null>(null);

  possibleFilters$ = merge(this.filterQuery.valueChanges, this.selectedFilters$, this.types$).pipe(
    startWith(['', []]),
    withLatestFrom(this.types$),
    map(([, types]) =>
      types
        .filter(
          (type) =>
            (!this.filterQuery.value || (!type.options && !type.onOpenOptionsDialog)) &&
            !this.selectedFilters$.getValue().find((filter) => filter.id === type.id)
        )
        .map((type) => ({
          ...type,
          title: type.name + (this.filterQuery.value ? `: ${this.filterQuery.value}` : ''),
          query: this.filterQuery.value ? { name: this.filterQuery.value, value: this.filterQuery.value } : undefined
        }))
    )
  );

  // hide the input if we've reached the max filters
  // or if there are no filters remaining that need the input
  hideInput$ = this.selectedFilters$.pipe(map((filters) => filters.length >= MAX_FILTERS));

  private destroy$ = new Subject<void>();

  constructor() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        // Read filter values from URL and apply it
        combineLatest([
          this.route.queryParams.pipe(startWith(this.route.snapshot.queryParams), shareReplay(1)),
          this.types$
        ])
          .pipe(takeUntil(this.destroy$))
          .subscribe(([params, types]) => {
            types
              .filter((type) => type.queryParam && params[type.queryParam])
              .forEach((type) => {
                const value: string = params[type.queryParam];
                let query: ListFilterItemValue<unknown> | ListFilterItemValue<unknown>[];
                if (type.multiple) {
                  if (type.options) {
                    query = JSON.parse(value).map((v: ListFilterItemValue<unknown>) => {
                      const option = type.options!.find((o) => o.value === (isObject(v) ? v.value : v));
                      return option ? { name: option.name, value: option.value } : { name: v, value: v };
                    });
                  } else {
                    query = JSON.parse(value).map((v: ListFilterItemValue<unknown>) => ({
                      name: v.name,
                      value: v.value
                    }));
                  }
                } else if (type.options) {
                  // if the value is json then compare json strings
                  const option = type.options.find(
                    (o) => (o.value === value && value.indexOf('{') === -1) || JSON.stringify(o.value) === value
                  );
                  query = option ? { name: option.name, value: option.value } : { name: value, value };
                } else {
                  query = { name: value, value };
                }

                this.updateSelectedFilters(type, query);
              });
          });
      });

    this.selectedFilters$
      .pipe(
      skip(1),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      debounceTime(100),
      takeUntil(this.destroy$)
      )
      .subscribe((filters) => {
      // update the URL with the new filter values
      const queryParams: Record<string, string | undefined> = {};
      filters.forEach((filter) => {
        if (filter.query instanceof Array) {
        queryParams[filter.queryParam] = JSON.stringify(
          filter.query.map((v) => {
          return { name: v.name, value: v.value };
          })
        );
        } else if (isObject(filter.query?.value)) {
        queryParams[filter.queryParam] = JSON.stringify(filter.query?.value);
        } else {
        queryParams[filter.queryParam] = filter.query?.value as string;
        }
      });

      // only remove filter related query params
      const currentParams = { ...this.route.snapshot.queryParams };
      Object.keys(currentParams)
        .filter((key) => filters.find((filter) => filter.queryParam === key) === undefined)
        .forEach((key) => {
        if (filters.find((filter) => filter.queryParam === key)) {
          queryParams[key] = undefined;
        } else {
          queryParams[key] = currentParams[key];
        }
        });

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true
      });

      this.filterChanged.emit(filters);
      });
  }

  ngOnInit(): void {
    // listen to the scroll event and on scroll force the panel to close
    this.scroll
      .scrolled()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.trigger.closePanel();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  groupBy(groupBy: GroupByField): void {
    this.selectedGroupBy$.next(groupBy);
    this.groupByChanged.emit(groupBy);
  }

  sort(sort: SortField): void {
    const sortField = this.selectedSort$.getValue();
    if (sortField?.field === sort.value) {
      this.toggleSortDirection();
      return;
    }

    const newSort = { field: sort.value, type: sort.type, direction: sort.defaultSortDirection ?? 'asc' };

    this.selectedSort$.next(newSort);

    this.sortChanged.emit(newSort);
  }

  toggleSortDirection(): void {
    const sort = this.selectedSort$.getValue() ?? ({ field: '', type: 'text', direction: 'asc' } as Sort);
    // set the icon based on the type of field
    let sortSvgIcon;
    switch (sort.type) {
      case 'numeric':
        sortSvgIcon = sort.direction === 'asc' ? 'sort-numeric-ascending' : 'sort-numeric-descending';
        break;
      case 'text':
        sortSvgIcon = sort.direction === 'asc' ? 'sort-alpha-ascending' : 'sort-alpha-descending';
        break;
      case 'date-range':
        sortSvgIcon = sort.direction === 'asc' ? 'sort-numeric-ascending' : 'sort-numeric-descending';
        break;
    }
    this.selectedSort$.next({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc', sortSvgIcon });

    this.sortChanged.emit(this.selectedSort$.getValue()!);
  }

  focusInput(): void {
    this.inputFocused = true;
    // hide the placeholder
    this.cd.markForCheck();

    // focus input
    // we have to wait a tick since mousedown events (caused by clicking on the placeholder)
    // are supressed and cause the panel to be closed
    // if we don't wait the input will focus and the panel will not open
    setTimeout(() => {
      this.filterInput.nativeElement.focus();
    }, 50);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // clear the input
    this.filterQuery.setValue('');

    // check if we need to show a dialog
    const filterItem = event.option.value as ListFilterItem<unknown>;
    this.trigger.closePanel();

    if (filterItem.query) {
      this.updateSelectedFilters(filterItem, filterItem.query as ListFilterItemValue);
      return;
    }

    this.showDialogBasedOnFilterItem(filterItem);
  }

  add(value: string): void {
    if (!value) {
      return;
    }
    // if there is only 1 filter type then assume that's what the user wants
    this.possibleFilters$.pipe(take(1)).subscribe((filters) => {
      if (filters.length === 1 && !filters[0].options) {
        this.filterQuery.setValue('');
        this.updateSelectedFilters(filters[0], { name: value, value });
        this.trigger.closePanel();
        // focus last chip
        this.filterInput.nativeElement.blur();
      }

      // if there are multiple filter types then do nothing
    });
  }

  edit(filterItem: ListFilterItem<unknown>): void {
    this.trigger.closePanel();

    this.showDialogBasedOnFilterItem(filterItem);
  }

  private async showDialogBasedOnFilterItem<T>(filterItem: ListFilterItem<T>): Promise<void> {
    let dialog: MatDialogRef<unknown> | undefined;
    if (filterItem.type === 'select' || filterItem.options || filterItem.onOpenOptionsDialog) {
      if (filterItem.onOpenOptionsDialog) {
        const result = await filterItem.onOpenOptionsDialog(filterItem.query as any);
        if (!result) {
          return;
        }

        this.updateSelectedFilters(filterItem, result);
        return;
      } else {
        dialog = await this.optionsDialog.open(
          {
            title: filterItem.name,
            options: filterItem.options!,
            multiple: filterItem.multiple,
            value: filterItem.query as ListFilterItemValue<T>
          },
          false
        );
      }
    }
    if (filterItem.type === 'text') {
      dialog = await this.inputDialog.open(
        {
          title: filterItem.name,
          inputLabel: filterItem.inputLabel,
          value: (filterItem.query as ListFilterItemValue<T> | undefined)?.value as string
        },
        false
      );
    }

    if (filterItem.type === 'date-range') {
      const value = (filterItem.query as ListFilterItemValue<DateRange>)?.value;

      dialog = await this.dateRangeDialog.open(
        {
          title: filterItem.name,
          startDate: value?.start as Date,
          endDate: value?.end as Date
        },
        false
      );
    }

    if (!dialog) {
      return;
    }

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.updateSelectedFilters(
        filterItem,
        result.value || filterItem.multiple ? result : { name: result, value: result }
      );
    });
  }

  private updateSelectedFilters<T>(
    filterItem: ListFilterItem<T>,
    value: ListFilterItemValue<T> | ListFilterItemValue<T>[]
  ) {
    if (!value) {
      return;
    }

    const changedFilter: ListFilterItem<T> = {
      ...filterItem,
      title: `${filterItem.name}: ${
        filterItem.multiple
          ? (value as ListFilterItemValue<T>[]).map((v) => v.name).join(', ')
          : ((value as ListFilterItemValue<T>).name ?? value)
      }`,
      query: value
    };

    const filters = this.selectedFilters$.getValue();
    // check if there is already a chip for this type
    const match = this.selectedFilters$.getValue().find((f) => f.id === filterItem.id);
    if (match) {
      // there is a match but the query is empty so remove the item
      if (Array.isArray(changedFilter.query) && changedFilter.query.length === 0) {
        filters.splice(filters.indexOf(match), 1);
      } else {
        // there is a match so update the existing item
        filters.splice(filters.indexOf(match), 1, changedFilter as ListFilterItem<unknown>);
      }
      this.selectedFilters$.next([...filters]);

      return;
    }

    if (Array.isArray(changedFilter.query) && changedFilter.query.length === 0) {
      return;
    }
    // add the new filter
    this.selectedFilters$.next([...filters, changedFilter as ListFilterItem<unknown>]);

    // if we've reached the max filters disable the input
    if (this.selectedFilters$.getValue().length === 4) {
      this.filterQuery.disable();
    }
  }

  remove(filter: ListFilterItem<unknown>): void {
    const selectedFilters = this.selectedFilters$.getValue();
    selectedFilters.splice(selectedFilters.indexOf(filter), 1);
    this.selectedFilters$.next([...selectedFilters]);

    this.filterChanged.emit(selectedFilters);
  }

  clear(): void {
    this.selectedFilters$.next([]);
  }
}
