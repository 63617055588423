import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { OptionPickerDialogOptions } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type OptionPickerDialogComponent } from './option-picker-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class OptionPickerDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open<T>(
    options: OptionPickerDialogOptions<T>,
    restoreFocus = true
  ): Promise<MatDialogRef<OptionPickerDialogComponent>> {
    const optionPickerDialogComponent = await import('./option-picker-dialog.component').then(
      (m) => m.OptionPickerDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(optionPickerDialogComponent, {
      data: options,
      panelClass: ['option-picker-dialog', 'basic-dialog'],
      restoreFocus,
      maxWidth: '560px',
      width: undefined,
      height: undefined,
      maxHeight: undefined
    });
  }
}
