<div class="filter-chip-list" *ngIf="selectedFilters$ | async as selectedFilters">
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let filter of selectedFilters"
      [removable]="true"
      (removed)="remove(filter)"
      (click)="edit(filter)"
    >
      {{ filter.title }}
      <mat-icon matChipRemove>
        <svg style="width: 18px; height: 18px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
          />
        </svg>
      </mat-icon>
    </mat-chip-row>
    <ng-container *ngIf="possibleFilters$ | async as possibleFilters">
      <button
        class="filter-chip-placeholder"
        *ngIf="!filterQuery.value && possibleFilters.length > 0"
        [hidden]="inputFocused"
        (click)="focusInput()"
      >
        <mat-icon class="filter-chip-placeholder-icon">
          <svg viewBox="0 0 24 24">
            <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </mat-icon>
        <span>Add a filter</span>
      </button>
      <input
        dir="auto"
        #filterInput
        class="filter-input"
        [hidden]="possibleFilters.length == 0"
        [ngClass]="{ focused: inputFocused }"
        [formControl]="filterQuery"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        (keydown.enter)="add(filterInput.value)"
        (click)="trigger.openPanel()"
        (focus)="inputFocused = true"
        (blur)="inputFocused = false"
        aria-label="Filter bar for members list"
      />
    </ng-container>
    @if (groupByFields().length > 0) {
      <button
        aria-label="Open group by menu"
        mat-icon-button
        matTooltip="Group by"
        [matMenuTriggerFor]="groupByMenu"
        trackClick="Comment Menu button clicked"
      >
        <mat-icon mat-menu-origin svgIcon="group"></mat-icon>
      </button>
      <mat-menu #groupByMenu="matMenu" xPosition="before" class="filter-menu">
        <button mat-menu-item *ngFor="let groupByField of groupByFields()" (click)="groupBy(groupByField)">
          <div class="filter-menu-item fx-layout-row">
            <span class="fx-flex">
              {{ groupByField.title }}
            </span>
            <mat-icon
              iconPositionEnd
              *ngIf="groupByField.value === ((selectedGroupBy$ | async)?.value ?? '')"
              svgIcon="check"
            ></mat-icon>
          </div>
        </button>
      </mat-menu>
    }
    @if (sortFields().length > 0) {
      <button
        aria-label="Open filter menu"
        mat-icon-button
        matTooltip="Sort by"
        [matMenuTriggerFor]="filterMenu"
        trackClick="Comment Menu button clicked"
      >
        <mat-icon mat-menu-origin svgIcon="sort"></mat-icon>
      </button>
      <mat-menu #filterMenu="matMenu" xPosition="before" class="filter-menu">
        <button mat-menu-item *ngFor="let sortField of sortFields()" (click)="sort(sortField)">
          <div class="filter-menu-item fx-layout-row">
            <span class="fx-flex">
              {{ sortField.title }}
            </span>
            <mat-icon
              iconPositionEnd
              *ngIf="
                sortField.value === (((selectedSort$ | async)?.field ?? '') && (selectedSort$ | async)?.sortSvgIcon)
              "
              [svgIcon]="(selectedSort$ | async)!.sortSvgIcon!"
            ></mat-icon>
          </div>
        </button>
      </mat-menu>
    }
  </mat-chip-grid>
  <button
    #clearFilter
    id="clearFilter"
    matTooltip="Clear all filters"
    [hidden]="selectedFilters.length == 0"
    mat-icon-button
    (click)="clear()"
  >
    <mat-icon
      ><svg class="" viewBox="0 0 24 24">
        <path
          d="M14.76,20.83L17.6,18L14.76,15.17L16.17,13.76L19,16.57L21.83,13.76L23.24,15.17L20.43,18L23.24,20.83L21.83,22.24L19,19.4L16.17,22.24L14.76,20.83M12,12V19.88C12.04,20.18 11.94,20.5 11.71,20.71C11.32,21.1 10.69,21.1 10.3,20.71L8.29,18.7C8.06,18.47 7.96,18.16 8,17.87V12H7.97L2.21,4.62C1.87,4.19 1.95,3.56 2.38,3.22C2.57,3.08 2.78,3 3,3V3H17V3C17.22,3 17.43,3.08 17.62,3.22C18.05,3.56 18.13,4.19 17.79,4.62L12.03,12H12Z"
        />
      </svg>
    </mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let filter of possibleFilters$ | async" [value]="filter">
      {{ filter.title }}
    </mat-option>
  </mat-autocomplete>
</div>
